import { Theme, Tooltip, Typography, withStyles } from '@material-ui/core'
import { LocationProps } from '@reach/router'
import { graphql, navigate } from 'gatsby'
import { TFunction } from 'i18next'
import * as moment from 'moment'
import React, { useEffect, useState } from 'react'
import { isAndroid } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import {
  generateTrackingLink,
  getBrands,
  getBrandsLogosURLSrc,
} from '../../utils/brandsHelper'
import { setItemToLocalStorage } from '../../utils/setLocalStorage'
import { trendingBrandsLogos } from '../../utils/trendingBrandsLogos'
import { useAppState } from '../appState'
import BrowserExtensionDesktop from '../assets/images/browser-extension-desktop.png'
import bird from '../assets/images/chirpyest-logo.svg'
import birdIcon from '../assets/images/chirpyestLogo.svg'
import insider from '../assets/images/insider.jpg'
import BrowserExtensionMobileImage from '../assets/images/use-browser-extension-mobile.webp'
import CHRButton from '../components/button'
import AndroidNoticeModal from '../components/joinModales/androidNoticeModal'
import BrandItem from '../components/newBrandItem'
import BrandCardsList from '../components/newBrandsList'
import SEO from '../components/seo'
import { FONTS, ROUTES } from '../constants'
import useNavigateToExtStore from '../hooks/useNavigateToExtStore'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/browserExtensionStyles'
import BlockUserInterface from './../components/blockUserInterface'
import ContentContainer from './../components/contentContainer'
import FaqsBrowserExtensionTab from './../components/helpPageTabs/faqsBrowserExtension'
import CHROutlinedButton from './../components/outlinedButton'

const HtmlTooltip = withStyles((theme: Theme) => ({
  arrow: {
    color: theme.palette.primary.main,
  },
  tooltip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.light,
    display: 'flex',
    alignItems: 'center',
    padding: 10,
  },
}))(Tooltip)

const quotations = [
  {
    type: 'insider',
    text: '“…[chirpyest] has a democratic angle over competitors…”',
    from: 'business insider, June 2022',
    link:
      'https://www.businessinsider.com/influencer-affiliate-marketing-chirpyest-colette-shelton-2022-5',
  },
  {
    type: 'customer',
    text:
      '“the chirpyest browser makes it easy to earn cash on every purchase…”',
    from: 'Anne, chirpyest member since 2022',
    link: '',
  },
]

const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

interface BrowserExtensionProps {
  data: {
    site: {
      siteMetadata: {
        title: string
      }
    }
    file: {
      childImageSharp: {
        fluid: any
      }
    }
  }
  t: TFunction
  location: LocationProps
  signInModal: boolean
  isTest?: boolean
}

const BrowserExtension = ({
  data,
  t,
  location,
  signInModal = false,
}: BrowserExtensionProps) => {
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [openAndroidNoticeModal, setOpenAndroidNoticeModal] = useState(false)
  const [appState] = useAppState()
  const classes = styles()
  const [landingBrands, setLandingBrands] = useState([])
  const navigateToStore = useNavigateToExtStore()[2]
  const isLandingPage = window.location.pathname === '/' ? true : false

  const urlParams = new URLSearchParams(window.location.search)
  const isTestPage = urlParams.get('isTest')

  useEffect(() => {
    getBrands()
      .then(response => {
        let filteredBrands = response.data.data.filter(item => item.isTrending)
        const filteredTrendingBrands = getBrandsLogosURLSrc(
          filteredBrands,
          trendingBrandsLogos
        )
        const limitedBrands = filteredTrendingBrands.slice(
          0,
          deviceType() === 'desktop' ? 16 : deviceType() === 'tablet' ? 12 : 8
        )
        setLandingBrands(limitedBrands)
      })
      .catch(() => setLandingBrands([]))
  }, [])

  if (
    location?.pathname.replace('/best-cash-back-browser-extension-website', '')
      .length > 0
  ) {
    setItemToLocalStorage(
      'referralId',
      JSON.stringify({
        id: location?.pathname
          .replace('/best-cash-back-browser-extension-website', '')
          .replace('/', ''),
        time: moment().format(),
      })
    )
  }
  setIsPageLoading(false)
  if (isPageLoading) {
    return <BlockUserInterface />
  } else {
    return (
      <Layout openSignInModal={signInModal}>
        <SEO
          title={t('browserExtension.metaTitle')}
          description={t('browserExtension.metaDescription')}
        />
        {/*---------------------- section 1 - The Chirpyest browser extension ----------------------*/}

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingLarge}>
            {isLandingPage
              ? 'shop. share. earn cash back!'
              : 'the chirpyest browser extension'}
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            {isLandingPage ? 'chirpyest' : ''} finds up to 30% cash back for you
            wherever you shop
          </Typography>
          <CHRButton
            onClick={() => navigate(ROUTES.joinWithModal)}
            label="join - it's free"
            icon={birdIcon}
            customStyle={classes.buttonStyles}
          />
        </section>

        {/*---------------------- section 2 - make cash while shopping + sharing ----------------------*/}

        <section className={classes.videoSections}>
          <div className={classes.leftVideoSection}>
            <Typography variant="h3" className={classes.headingMedium}>
              make cash back while shopping + sharing
            </Typography>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.paragraphStyle}
            >
              see how to make cash back from 1000s of brands using chirpyest
              browser extension - takes less than a minute to watch the video!
            </Typography>
            <AndroidNoticeModal
              open={openAndroidNoticeModal}
              onClose={() => setOpenAndroidNoticeModal(false)}
            />
            <CHROutlinedButton
              label="get the extension"
              font={FONTS.Graphik.GraphikMedium}
              onClick={() => {
                if (isAndroid) {
                  setOpenAndroidNoticeModal(true)
                  return
                }
                navigateToStore()
              }}
              customStyle={
                classes.buttonOutlineStyles +
                ' ' +
                classes.outlineButtonWidthStyle
              }
            />
          </div>
          <div className={classes.rightVideoSection}>
            {deviceType() === 'desktop' ? (
              <video controls poster={BrowserExtensionDesktop}>
                <source
                  src={
                    'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/7/Browser+Extension+Screencast+-+Desktop+-+Extension+Store.mp4'
                  }
                  type="video/mp4"
                />
                Sorry, your browser doesn't support embedded videos.
              </video>
            ) : (
              <video
                controls
                poster={BrowserExtensionMobileImage}
                rel="preload"
              >
                <source
                  src={
                    'https://chirpyest-public-videos.s3.us-east-2.amazonaws.com/new-videos/7/Browser+Extension+Screencast+-+Mobile+-+Extension+Store.mp4'
                  }
                  type="video/mp4"
                />
                Sorry, your browser doesn't support embedded videos.
              </video>
            )}
          </div>
        </section>

        {/*---------------------- section 3 - Step 1: create a free chirpyest account  ----------------------*/}
        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingMedium}>
            step 1: create a free chirpyest account
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            takes two seconds to sign up... thousands of people have done it...
            you should too!
          </Typography>
          <CHROutlinedButton
            label={'create free account now'}
            font={FONTS.Graphik.GraphikMedium}
            onClick={() => navigate(ROUTES.join)}
            customStyle={classes.buttonOutlineStyles}
          />
        </section>

        {/*---------------------- section 4 - Step 2: Add the free browser extension  ----------------------*/}

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingMedium}>
            step 2: add the free browser extension
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            visit the extension store, hit add and the browser extension is
            automatically added to your tool bar
          </Typography>
          <CHROutlinedButton
            label={'get browser extension'}
            font={FONTS.Graphik.GraphikMedium}
            onClick={navigateToStore}
            customStyle={classes.buttonOutlineStyles}
          />
        </section>

        {/*---------------------- section 5 - Step 3: Add the free browser extension  ----------------------*/}

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingMedium}>
            step 3: start shopping anywhere on the web
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            the chirpyest browser extension automatically detects when cash back
            is available from 1000s of online retailers - see all the brands
          </Typography>
          <CHROutlinedButton
            label={'see 850+ brands'}
            font={FONTS.Graphik.GraphikMedium}
            onClick={() => navigate(ROUTES.brands)}
            customStyle={classes.buttonOutlineStyles}
          />
        </section>

        {/*---------------------- section 6 - Step 4: Earn cash back for sharing too   ----------------------*/}

        <section className={classes.landingSections}>
          <Typography variant="h3" className={classes.headingMedium}>
            step 4: earn cash back for sharing too
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.paragraphStyle}
          >
            recommend products to friends and earn more cash back using the
            chirpyest browser extension
          </Typography>
          <CHROutlinedButton
            label={'see who is sharing'}
            font={FONTS.Graphik.GraphikMedium}
            onClick={() => navigate(ROUTES.theChirpyest)}
            customStyle={classes.buttonOutlineStyles}
          />
        </section>

        {/*---------------------- section 7 - BRANDS LIST ----------------------*/}

        <div className={classes.flexOrder}>
          <div className={classes.brandsListStyle}>
            <div className={classes.mobileBackgroundStyle}>
              <section className={classes.brandsListSection}>
                {deviceType() !== 'desktop' && (
                  <div className={classes.brandsListTitle}>
                    <Typography variant="h2" className={classes.brandsHeading}>
                      {t('home.thousandsOfBrands')}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={classes.subtitleBrandsSentence}
                    >
                      {t('home.homeFashionBeauty')}
                    </Typography>
                  </div>
                )}

                <div className={classes.brandsListContainer}>
                  <BrandCardsList>
                    {landingBrands.map((brand: any, index: number) => {
                      return (
                        <BrandItem
                          brandLogo={brand.src}
                          name={brand.brandName}
                          cashBack={brand.commission}
                          isCashbackShownInWebsite={
                            brand.isCashbackShownInWebsite
                          }
                          link={generateTrackingLink(
                            brand,
                            {
                              userId: appState.userId,
                              userName: appState.userProfile?.userName,
                            },
                            '',
                            '',
                            Boolean(appState.userId)
                          )}
                          key={index}
                          isCardOnLanding
                        />
                      )
                    })}
                  </BrandCardsList>
                </div>
                <div className={classes.brandsListTitle}>
                  {deviceType() === 'desktop' && (
                    <>
                      <Typography
                        variant="h2"
                        className={classes.brandsHeading}
                      >
                        {t('home.thousandsOfBrands')}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        className={classes.subtitleBrandsSentence}
                      >
                        {t('home.homeFashionBeauty')}
                      </Typography>
                    </>
                  )}
                  <div className={classes.addChirpyestButtonStyle}>
                    <CHROutlinedButton
                      label={'all brands'}
                      font={FONTS.Graphik.GraphikMedium}
                      onClick={() => navigate(ROUTES.brands)}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        {/*---------------------- section 8 - Quotations ----------------------*/}

        <section className={classes.landingSections}>
          <Carousel
            swipeable={true}
            showArrows={false}
            showThumbs={false}
            emulateTouch={true}
            showStatus={false}
          >
            {quotations.map((quote, index) => {
              return (
                <div key={index}>
                  {quote.type === 'insider' && (
                    <>
                      <img
                        src={insider}
                        alt="insider"
                        className={classes.quoteImage}
                      />
                      <HtmlTooltip
                        interactive
                        title={
                          <a
                            href={quote.link}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.whiteLink}
                          >
                            business insider, june 2022
                          </a>
                        }
                      >
                        <a
                          href={quote.link}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.link}
                        >
                          <Typography variant="h3" className={classes.quote}>
                            {quote.text}
                          </Typography>
                        </a>
                      </HtmlTooltip>
                    </>
                  )}
                  {quote.type === 'customer' && (
                    <>
                      <img
                        src={bird}
                        alt="customer"
                        className={classes.quoteImage}
                      />
                      <Typography variant="h3" className={classes.quote}>
                        {quote.text}
                      </Typography>
                    </>
                  )}

                  <Typography
                    variant="subtitle1"
                    className={classes.subtitleBrandsSentence}
                  >
                    {quote.from}
                  </Typography>
                </div>
              )
            })}
          </Carousel>
        </section>

        {/*------------ section 9 - What are you waiting for ------------*/}

        <div className={classes.sectionContainer + ' ' + classes.waitingFor}>
          <ContentContainer>
            <section className={classes.centered}>
              <Typography
                variant="h1"
                component="h2"
                className={classes.headingMedium + ' ' + classes.centerHeading}
              >
                {t('home.whatAreYouWaiting')}
              </Typography>
              <CHROutlinedButton
                label={'get started'}
                font={FONTS.Graphik.GraphikMedium}
                onClick={() => navigate(ROUTES.join)}
                customStyle={
                  classes.buttonStyles + ' ' + classes.buttonWidthStyle
                }
              />
              <Typography
                component="h1"
                variant="h1"
                className={classes.seoHeading}
              >
                {t('browserExtension.h1')}
              </Typography>
            </section>
          </ContentContainer>
        </div>

        {/*------------ section 10 -  what is the chirpyest browser extension ------------*/}

        <section className={classes.extensionSections}>
          <Typography
            variant="h3"
            className={classes.headingMedium + ' ' + classes.headingMediumSmall}
          >
            what is the chirpyest browser extension?
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.headingSmall}
          >
            chirpyest is a cash back browser extension with a new twist.
            chirpyest browser extensions allows anyone to earn up to 30% cash
            back while shopping directly on 1000s of retailers' sites.
            <br /> <br />
            The Chirpyest browser extension is a simple tool that is easily
            added to your search engine tool bar on your desktop or laptop. When
            added, the browser extension does the work on finding cash back from
            1000s of retailers including lululemon, wayfair, pottery barn, the
            container store, neiman marcus, anthropologie, the home depot, VRBO
            and so many more! <br /> <br />
            The new twist is that chirpyest is the only browser extension that
            allows you to share product finds and earn even more cash back. On
            the extension, you can find our "create a “share link”" button and
            "add to shopping board" features. These two tools give consumers the
            power to earn money for making recommendations to friends. <br />{' '}
            <br />
            chirpyest was created by interior design blogger Colette Shelton who
            created Forbes ranked blog COCOCOZY. Colette saw that so many of her
            friends and followers were not earning money for shopping and she
            wanted to give them new tools they could use to earn. Chirpyest is a
            black owned business with a black founder. Colette built her own
            social commerce tech company because wanted to make sure that
            everyone, including people of color, were able find their voice and
            own their style.
          </Typography>
        </section>

        {/*------------ section 11 - how to get the chirpyest browser extension ------------*/}

        <section className={classes.extensionSections}>
          <Typography
            variant="h3"
            className={classes.headingMedium + ' ' + classes.headingMediumSmall}
          >
            how to get the chirpyest browser extension
          </Typography>
          <Typography
            variant="subtitle1"
            component="p"
            className={classes.headingSmall}
          >
            just visit the extension store, add the free chirpyest browser
            extension to your tool bar and then start shopping anywhere on the
            web. You have to create a free chirpyest account and remember to
            enter your paypal or venmo. 30-120 days after you make the purchase
            at an affiliated retailer, you will be paid cash back. No coins, no
            points, no loyalty rewards, you will simply be paid cash back.
          </Typography>
        </section>

        {/*------------ section 12 - FAQ's ------------*/}

        <section className={classes.extensionSections}>
          <div className={classes.productsContainer}>
            <div>
              <Typography variant="h2" className={classes.headingMargin}>
                iykyk… for those who frequently ask…
              </Typography>
            </div>
            <div className={classes.tabPanelSection}>
              <TabPanel value={0} index={0}>
                <FaqsBrowserExtensionTab
                  brand={'converse'}
                  commission={'30%'}
                />
              </TabPanel>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default withTranslation()(BrowserExtension)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "chirpyest-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
